<template>
  <div>
    <v-card
        max-width="400"
        class="mx-auto"
    >
      <v-app-bar
          dark
          color="#455A64"
      >
        <v-toolbar-title>{{ $t('department') }}</v-toolbar-title>
      </v-app-bar>
      <v-container>
        <v-row dense>
          <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              @click="clickDep(item.title)"
          >
            <v-card
                :color="item.color"
                dark
                style="cursor: pointer"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <img width="115px;" height="120px" :src="item.url"/>
                <div>
                  <v-card-title
                      style="word-break: break-word;"
                      class="text-h7"
                      v-text="$t(item.title)"
                  ></v-card-title>
                  <v-card-subtitle style="text-align: left" v-text="item.body.substr(0,50)"></v-card-subtitle>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>
<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";

export default {
  name: "SideBarDepartment",
  mixins: [MyMixin],
  data() {
    return {
      items: Constants.departmentList
    }
  },
  methods: {
    clickDep(dep) {
      this.$emit("clickDep",dep);
    }
  },
}

</script>

