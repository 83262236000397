<template>
  <div>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('electricity_department')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
              v-for="(d,i) in infoList"
              :key="i"
              dark
              large
          >
            <template v-slot:icon>
              <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                <img v-if="d.url" :src="d.url">
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
            </template>
            <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
              <v-card-title class="text-h5">
                <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

              </v-card-title>
              <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

              <v-divider inset style="color: green"></v-divider>

              <v-card-text style="text-align: left">
                <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

              </v-card-text>
            </v-card>
          </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'etDepartment',
  data(){
    return{
        infoList:[
          {
            name:"bev_chanthorn",
            position:"chief",
            phoneNumber:"+855-81 80 69 26",
            bachelor:"bachelor_of_technology",
            master:"",
            phd:"",
            email:"changborong@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F8%20-%20bev%20chanthorn.jpg?alt=media&token=b6267c77-4373-4634-a3c1-9f14a73962d9"
          },{
            name:"sim_sophors",
            position:"deputyChief",
            phoneNumber:"+855-87 704 488",
            bachelor:"civil_engineering",
            master:"",
            phd:"",
            email:"sim.simsophors@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fet%2Fphoto_2019-08-30_14-49-54%20-%20Sim%20Sophors.jpg?alt=media&token=3dc0e19d-ddc4-4585-a22b-aa624061729c"
          },{
            name:"ieng_veasna",
            position:"technicalTeacher",
            phoneNumber:"+855-93 503 600",
            bachelor:"electricity",
            master:"",
            phd:"",
            email:"iengveasna285@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fet%2FIMG_4071%20copy%20-%20ieng%20veasna.jpg?alt=media&token=0cd49ded-0d49-46f0-ac4c-59dc3501da13"
          },

        ]

    }
  },
}
</script>
