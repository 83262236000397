<template>
  <div>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('mechanic_department')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
              v-for="(d,i) in infoList"
              :key="i"
              dark
              large
          >
            <template v-slot:icon>
              <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                <img v-if="d.url" :src="d.url">
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
            </template>
            <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
              <v-card-title class="text-h5">
                <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

              </v-card-title>
              <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

              <v-divider inset style="color: green"></v-divider>

              <v-card-text style="text-align: left">
                <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

              </v-card-text>
            </v-card>
          </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'mechanicDepartment',
  data(){
    return{
        infoList:[
          {
            name:"duch_sarith",
            position:"director",
            phoneNumber:"+855-69 339 004",
            bachelor:"electricity",
            master:"",
            phd:"",
            email:"duchsarith1990@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FIMG20210519193411%20-%20SR%20electricity%20Electrical%20Engineering%20Technology-min.jpg?alt=media&token=d886637e-69a1-4502-a967-060cd363484e"
          },
          {
            name:"duong_vannakrinn",
            position:"technicalTeacher",
            phoneNumber:"+855-93 717 870",
            bachelor:"bachelor_of_technology",
            master:"",
            phd:"",
            email:"vanna.vannakmetfone@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fmechanic%2F20210902_164328%20-%20Vannakrinn%20Doung-min.png?alt=media&token=8f080686-28ca-4b6a-bee4-42e9f924a3d8"
          },
          {
            name:"khvan_naret",
            position:"technicalTeacher",
            phoneNumber:"+855-10 908 866",
            bachelor:"mechanic_of_automobile",
            master:"",
            phd:"",
            email:"khvannaret11@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fmechanic%2FC27C74CB-DE01-4BDC-A394-D23C683857C0%20-%20Naret%20khvan.jpeg?alt=media&token=a8e13621-d6ad-44c3-b0f8-ea6d5f153c5e"
          },
          {
            name:"heng_reaksa",
            position:"technicalTeacher",
            phoneNumber:"+855-86 549 193",
            bachelor:"",
            associate:"mechanic_of_automobile",
            master:"",
            phd:"",
            email:"hengraksa2017@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fmechanic%2F80710572_621276445300186_5214632399526166528_n%20-%20Travel%20in%20the%20world%20Raksa-min.jpg?alt=media&token=6f1c3b34-43df-4df0-bc84-97ab010db31a"
          },
          {
            name:"van_bunthean",
            position:"technicalTeacher",
            phoneNumber:"+855-",
            bachelor:"",
            master:"",
            phd:"",
            email:"@gmail.com",
            url:""
          },

        ]

    }
  },
}
</script>
