<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="8" md="8">
          <it-department v-if="department==='it_department'"></it-department>
          <el-department v-if="department==='electronic_department'"></el-department>
          <et-department v-if="department==='electricity_department'"></et-department>
          <mec-department v-if="department==='mechanic_department'"></mec-department>
          <agr-department v-if="department==='agriculture_and_food_processing_department'"></agr-department>
          <fl-department v-if="department==='foreign_language_department'"></fl-department>
        </v-col>

        <v-col cols="12" sm="4" md="4" v-if="!$vuetify.breakpoint.mobile">
          <side-bar-department @clickDep="chooseDepartment"></side-bar-department>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import SideBarDepartment from "@/components/sidebarDepartment";
import itDepartment from "@/components/structure/itDepartment";
import etDepartment from "@/components/structure/electricityDepartment";
import elDepartment from "@/components/structure/electronicDepartment";
import mecDepartment from "@/components/structure/mechanicDepartment";
import flDepartment from "@/components/structure/flDepartment";
import agrDepartment from "@/components/structure/agrDepartment";

export default {
  components: {SideBarDepartment, itDepartment,elDepartment,etDepartment,mecDepartment,flDepartment,agrDepartment},
  name: "StructureDepartment",
  data() {
    return {
      department: "it_department"
    }
  },
  methods: {
    chooseDepartment(dep) {
      this.department = dep;
    }
  },
}
</script>