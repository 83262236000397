<template>
  <div>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('it_department')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
              v-for="(d,i) in infoList"
              :key="i"
              dark
              large
          >
            <template v-slot:icon>
              <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                <img v-if="d.url" :src="d.url">
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
            </template>
            <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
              <v-card-title class="text-h5">
                <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

              </v-card-title>
              <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

              <v-divider inset style="color: green"></v-divider>

              <v-card-text style="text-align: left">
                <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

              </v-card-text>
            </v-card>
          </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'itDepartment',
  data(){
    return{
        infoList:[
          {
            name:"ly_kimleng",
            position:"chief",
            phoneNumber:"+855-77 220 004",
            bachelor:"computer_science_engineering",
            master:"computer_science_engineering",
            phd:"",
            email:"lykimleng.rupp@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FFB_IMG_1606119786228%20-%20Coding%20Toy.jpg?alt=media&token=594f5bc8-8f00-4597-96ac-7db6e9cd3b2b"
          },
          {
            name:"leang_kimlorn",
            position:"deputyChief",
            phoneNumber:"+855-15 451 919",
            bachelor:"it",
            master:"",
            phd:"",
            email:"leangkimlorn@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fit%2Fphoto_2021-09-01_16-16-34%20-%20leang%20kimlorn.jpg?alt=media&token=a3f980e9-2c60-4d88-b43b-1bbda0748342"
          },
          {
            name:"rim_samrith",
            position:"technicalTeacher",
            phoneNumber:"+855-87 997 763",
            bachelor:"it",
            master:"it",
            phd:"",
            email:"samrithkh2009@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fit%2FIMG_20210813_093801_091%20-%20Rim%20Samrith.jpg?alt=media&token=512563c9-8809-44b7-9fbf-99a6ee11e1b7"
          },
          {
            name:"phan_thoul",
            position:"technicalTeacher",
            phoneNumber:"+855-92 77 93 93",
            bachelor:"it",
            master:"",
            phd:"",
            email:"phanthoul@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fit%2FDS%2005-05-21%20-%20Molika%20Phan-min.JPG?alt=media&token=41e22e73-2dde-443e-ad94-750534a1fcf7"
          },
          {
            name:"heng_bora",
            position:"technicalTeacher",
            phoneNumber:"+855-",
            bachelor:"it",
            master:"",
            phd:"",
            email:"@gmail.com",
            url:""
          },
          {
            name:"kao_satra",
            position:"technicalTeacher",
            phoneNumber:"+855-92 886 986",
            bachelor:"it",
            master:"management_business_administrator",
            phd:"",
            email:"kaosatra@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fit%2Fstra1%20-%20Gok%20Technology%20Center.jpg?alt=media&token=f2cd11cb-64d2-43eb-912b-cc98defd92c8"
          },
          {
            name:"phan_chanra",
            position:"technicalTeacher",
            phoneNumber:"+855-",
            bachelor:"it",
            master:"",
            phd:"",
            email:"@gmail.com",
            url:""
          },

        ]

    }
  },
}
</script>
