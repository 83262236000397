<template>
  <div>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('electricity_department')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
              v-for="(d,i) in infoList"
              :key="i"
              dark
              large
          >
            <template v-slot:icon>
              <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                <img v-if="d.url" :src="d.url">
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
            </template>
            <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
              <v-card-title class="text-h5">
                <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

              </v-card-title>
              <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

              <v-divider inset style="color: green"></v-divider>

              <v-card-text style="text-align: left">
                <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

              </v-card-text>
            </v-card>
          </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'elDepartment',
  data(){
    return{
        infoList:[
          {
            name:"chheun_tola",
            position:"chief",
            phoneNumber:"+855-98 804 363",
            bachelor:"electronic",
            master:"",
            phd:"",
            email:"chheuntola12@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Faaaaaaaaaaaaaaaaa%20-%20%E1%9E%8F%E1%9E%BB%E1%9E%9B%E1%9E%B6%20%E1%9E%88%E1%9E%BA%E1%9E%93-min.png?alt=media&token=80c46684-435a-4309-aa96-8dc95b34ec57"
          },{
            name:"thet_yatith",
            position:"technicalTeacher",
            phoneNumber:"+855-69 855 129",
            bachelor:"electronic",
            associate:"",
            master:"",
            phd:"",
            email:"thetyatith088@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fel%2FDSC_00f09%20copy%20-%20Thet%20Yatith-min.JPG?alt=media&token=61e463ff-dfcc-4eb5-9b7f-541963217c04"
          },{
            name:"touch_songly",
            position:"technicalTeacher",
            phoneNumber:"+855-",
            bachelor:"",
            associate:"",
            master:"",
            phd:"",
            email:"@gmail.com",
            url:""
          },

        ]

    }
  },
}
</script>
