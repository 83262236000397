<template>
  <div>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('foreign_language_department')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
              v-for="(d,i) in infoList"
              :key="i"
              dark
              large
          >
            <template v-slot:icon>
              <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                <img v-if="d.url" :src="d.url">
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
            </template>
            <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
              <v-card-title class="text-h5">
                <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

              </v-card-title>
              <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

              <v-divider inset style="color: green"></v-divider>

              <v-card-text style="text-align: left">
                <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

              </v-card-text>
            </v-card>
          </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'flDepartment',
  data(){
    return{
        infoList:[
          {
            name:"yin_sengheang",
            position:"chief",
            phoneNumber:"+855-93 888 799",
            bachelor:"english",
            master:"",
            phd:"",
            email:"Yin.sengheang99@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FB612_20210901_153603_472%20-%20SENGHEANG%20YIN-min.jpg?alt=media&token=fdc4f020-6b9e-42a3-bf23-689c892531cd"
          },
          {
            name:"keo_senghun",
            position:"deputyChief",
            phoneNumber:"+855-92 925 373",
            bachelor:"banking_and_fiance",
            master:"",
            phd:"",
            email:"senghunkeo92@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ffl%2FIMG_20210901_171145_448%20-%20Seng%20Hun.jpg?alt=media&token=9ddd0018-f22b-40e6-9fd5-5d58a324e71c"
          },
          {
            name:"sam_visarak",
            position:"technicalTeacher",
            phoneNumber:"+855-92 622 627",
            bachelor:"accounting_and_finance",
            master:"business_administration",
            phd:"",
            email:"visarak.lecture@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ffl%2FVisarak'photo%20-%20Visarak%20Lecture.jpg?alt=media&token=32efbb9d-3b34-4b5c-a064-7237414ec8ce"
          },
          {
            name:"sok_savoeun",
            position:"technicalTeacher",
            phoneNumber:"+855-96 709 6168",
            bachelor:"english",
            master:"",
            phd:"",
            email:"savoeunsok168@yahoo",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ffl%2FIMG_20210109_070350_497%20-%20sok%20savoeun-min.JPG?alt=media&token=b4201175-afda-4c4e-8432-308156b99482"
          },
          {
            name:"prak_thyly",
            position:"technicalTeacher",
            phoneNumber:"+855-",
            bachelor:"",
            master:"",
            phd:"",
            email:"@yahoo",
            url:""
          },

        ]

    }
  },
}
</script>
