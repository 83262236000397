<template>
  <div>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('agriculture_and_food_processing_department')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
              v-for="(d,i) in infoList"
              :key="i"
              dark
              large
          >
            <template v-slot:icon>
              <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                <img v-if="d.url" :src="d.url">
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
            </template>
            <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
              <v-card-title class="text-h5">
                <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

              </v-card-title>
              <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

              <v-divider inset style="color: green"></v-divider>

              <v-card-text style="text-align: left">
                <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

              </v-card-text>
            </v-card>
          </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'agrDepartment',
  data(){
    return{
        infoList:[
          {
            name:"kung_menghak",
            position:"chief",
            phoneNumber:"+855-77 264 555",
            bachelor:"agronomy",
            master:"sustainable_agriculture",
            phd:"",
            email:"menghak.kung@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F0E41FF81-BFC5-466E-AF69-C180435BB449%20-%20kung%20menghak-min.jpeg?alt=media&token=cbe94925-f703-4c89-a54e-1ae727335f67"
          },
          {
            name:"din_chamroeun",
            position:"deputyChief",
            phoneNumber:"+855-12 392 633",
            bachelor:"animal_science_and_veterinary_medicine",
            master:"sustainable_agriculture",
            phd:"",
            email:"chamroeun168.crv@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fagri%2Fphoto_2021-03-27_18-23-37%20-%20chamroeun%20din.jpg?alt=media&token=a89e09b7-38d1-4ecd-994d-b79000b48a7d"
          },
          {
            name:"phoeng_sophea",
            position:"deputyChief",
            phoneNumber:"+855-15 775 572",
            bachelor:"agronomy",
            master:"",
            phd:"",
            email:"sophea775572@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fagri%2Fphoto_2021-09-01_10-06-04%20-%20sophea%20phoeng.jpg?alt=media&token=c84feb11-b8e2-4652-a9f0-0e8f9e1978f0"
          },
          {
            name:"tum_sokea",
            position:"technicalTeacher",
            phoneNumber:"+855-12 757 998",
            bachelor:"agricultural_science",
            associate:"english",
            master:"biological_control",
            phd:"",
            email:"sokea.tsk@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fagri%2FWeb%20-%20Davy%20Ek.JPG?alt=media&token=13cb3cce-3745-4887-badb-14744a9b16b2"
          },

        ]

    }
  },
}
</script>
